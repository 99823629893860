/* Customize arrow colors */
.slick-prev:before, 
.slick-next:before {
  color: #171443; /* Set the color for the arrows */
}

/* Center content inside the slider */
.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.slick-slide > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px; /* Adjust the margin */
    padding: 10px;
}

/* Gradient overlay */
.overlay {
  background: linear-gradient(to bottom, rgba(23, 20, 67, 0) 0%, rgba(23, 20, 67, 0.8) 100%); /* From 0% opacity to primary color */
  transition: background 0.5s; /* Smooth transition */
}

/* On hover, the gradient becomes darker */
.overlay:hover {
  background: linear-gradient(to bottom, rgba(23, 20, 67, 0) 0%, rgba(23, 20, 67, 0.8) 100%); /* Darker gradient on hover */
}

/* Adjust arrow position for better appearance */
.slick-prev {
  left: -30px; /* Adjust left arrow position */
}

.slick-next {
  right: -30px; /* Adjust right arrow position */
}

/* Style the dots */
.slick-dots li button:before {
  color: #171443; /* Change the color of dots */
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #171443; /* Active dot color */
}
